import "./DeliveryAddress.css";
import { useUserData } from "../../../../contexts/UserDataProvider.js";
import { v4 as uuid } from "uuid";

import React from "react";
import { toast } from "react-hot-toast";
import { useAuth } from "../../../../contexts/AuthProvider.js";
import { useNavigate } from "react-router-dom";
import {  closePaymentModal, useFlutterwave } from 'flutterwave-react-v3';
import { addOrders } from "../../../../services/order-services/index.js";
import { gaTrackEvent } from "../../../../utils/analytics.js";
import { generateRandomNumber } from "../../../../utils/index.js";
import { PaystackButton, usePaystackPayment } from 'react-paystack';

export const DeliveryAddress = ({deliveryMethod}) => {
  const { userDataState, dispatch, clearCartHandler } = useUserData();
  const {
    cartProducts,
    addressList,
    orderDetails: { cartItemsDiscountTotal, orderAddress },
  } = userDataState;


  const totalAmount =  parseInt(cartItemsDiscountTotal) + deliveryMethod?.amount
  const transaction_id = generateRandomNumber(10)

  const navigate = useNavigate();

  const userContact = addressList?.find(
    ({ _id }) => _id === orderAddress?._id
  )?.phone;
  const { auth, setCurrentPage } = useAuth();

  const config = {
    public_key: 'FLWPUBK-e7ec25431085f5c97d020a92e92b8ab7-X',
    tx_ref: Date.now(),
    amount: Number(totalAmount) ,
    currency: 'NGN',
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      name: auth.firstName || localStorage.getItem("firstName"),
      email: auth.email || localStorage.getItem("email"),
      phone_number: userContact,
    },
    customizations: {
      title: "360gadgetsafrica",
      description: 'Payment for items in cart',
      logo: 'https://d4ian0pscdjji.cloudfront.net/logo.png',
    },
  };
  const payStackConfig = {
    reference: (new Date()).getTime().toString(),
    email:  auth.email || localStorage.getItem("email"),
    amount:Number(totalAmount) * 100 , //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: 'pk_live_2cba6fb0d9a4cd6e4ab9a8f13408e1dc2525e77a',
  }
  const handleFlutterPayment = useFlutterwave(config);
  const initializePayment = usePaystackPayment(payStackConfig);
  const placeOrderHandler = (type) => {
    gaTrackEvent({label: 'add_payment_info', type: 'add_payment_info', category: 'Ecommerce', data: {value:  Number(totalAmount)}})
    if (orderAddress) {
      if(type == 'paystack'){
        initializePayment({
          onSuccess: success,
          onClose: () => {},
        });
      } else {
        handleFlutterPayment({
          callback: success,
          onClose: () => {},
        });
      }
    } else {
      toast("Please select an address!");
    }
  };
  const pod = () =>  {
    success({
      status: 'completed',
      transaction_id,
      tx_ref: transaction_id,
      amount: Number(totalAmount) ,
    })
  }
  const success = async (response) => {
    console.log(response)
    if(response.status == 'success'){
      response.status =  'completed'
      response.amount = Number(totalAmount)
      response. transaction_id = response.transaction
      response. tx_ref = response.trxref
    }
   if(response.status == 'successful' || response.status == "completed"){
      try {
        var ids = cartProducts?.map(p => p?.productId?.slug)
        window.fbq('track', 'Purchase', {value: response.amount, currency: 'NGN', content_ids: ids,  content_type: 'product', });
      gaTrackEvent({label: 'purchase', type: 'purchase', category: 'Ecommerce', data: {value: response.amount, currency: 'NGN', transaction_id}})
      window.twq('event', 'tw-onvnf-onvnf', {
      });
      const order = {
        flutterwave: {
          transaction_id: response.transaction_id,
          tx_ref: response.tx_ref,
        },
        amountPaid: response.amount,
        orderedProducts: [...cartProducts],
        deliveryAddress: { ...orderAddress, ...deliveryMethod },
      };
const res = await addOrders(order)
        dispatch({ type: "SET_ORDERS", payload: res.data });
        clearCartHandler();
        setCurrentPage("orders");
        navigate("/profile/orders");
        toast.success("Your purchase was successful. We will contact you soon!");
      } catch (error) {
        toast("An error has occured please contact us for additional support");
      }
  
  
    }
      closePaymentModal() // this will close the modal programmatically
  }
  return (
    deliveryMethod?.deliveryType == 'Pickup' ? 
    <div className="delivery-address-container">
      
      <p>Pickup Address</p>

      <div className="delivery-address-description">
        <span className="name">  
            His Grace Plaza, 14 Francis Oremeji St, Ikeja, 101233, Lagos
          <a href="https://maps.app.goo.gl/XteHMHUwDpzEpukc6" target="_blank" > Locate on map</a>
          </span>
        <br />
        <button  onClick={pod} className="place-order-btn pod">
         Place order
        </button>
        <br />
        {/* <FlutterWaveButton {...fwConfig} /> */}
      </div>
    </div> : 
    <div className="delivery-address-container">
    <p>Delivery date</p>
    <div className="delivery-address-description">
    <span className="name">
    Arrive in 24 - 48hours
    </span>
    </div>
  <p>Delivering To</p>

  <div className="delivery-address-description">
    <span className="name">
      Name: {userDataState.orderDetails?.orderAddress?.name}
    </span>
    {/* <span className="address">
      Address: {orderAddress?.street}, {orderAddress?.city},{" "}
      {orderAddress?.state}, {orderAddress?.country},{" "}
      {orderAddress?.pincode}
    </span> */}
     <span className="address">
      City: {orderAddress?.state}  
    </span>
    <span className="contact">Contact: {orderAddress?.phone}</span>
    <br />

    <button  onClick={()=>placeOrderHandler('paystack')} className="place-order-btn paystack">
     Pay with <img src="https://360gadgetsafrica.s3.eu-north-1.amazonaws.com/paystack.png" width={"100px"} /> 
    </button>
    {Number(totalAmount) <= 500000 && <button  onClick={placeOrderHandler} className="place-order-btn ">
     Pay with flutterwave 
    </button>}
    <br />
    {/* <FlutterWaveButton {...fwConfig} /> */}
  </div>
</div>
  );
};
