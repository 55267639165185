import React from "react";
import ReactPlayer from "react-player";
import "./VideosSection.css";
import Tilt from "react-parallax-tilt";
import { useNavigate } from "react-router-dom";

export const VideosSection = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="video-container">
        <Tilt transitionSpeed={1000} scale={1.07} className="video-card" >
          <div>
          <img onClick={()=>navigate('/product-details/No-box-non-active-iWatch-series-9-41mm-26838')} 
          src="https://terra01.s3.amazonaws.com/images/96fb5109-e250-4389-a4ee-db13d2cbcbef.jpeg"
          />
          <h3>Iwatch Series 9</h3>
          <span className="notch"></span>
          </div>
        </Tilt>{" "}
        <Tilt transitionSpeed={1000} scale={1.07} className="video-card">
          <div onClick={()=>navigate("/product-details/Brand-New-Google-Pixel-8A-128GB--24967")} >
          <img  src="https://terra01.s3.amazonaws.com/images/21f87224-71fe-491e-afdd-1ad2751b31b2.jpeg"/>
          <h3>Google Pixel 8</h3>
          <span className="notch"></span>
          </div>
        </Tilt>
        <Tilt transitionSpeed={1000} scale={1.07} className="video-card">
          
        <div onClick={()=>navigate('/product-details/HP-EliteBook-1040-G6-x360--45141')} >
          <img src="https://terra01.s3.amazonaws.com/images/WhatsApp%20Image%202024-08-12%20at%2011.24.23%20AM.jpeg" alt="" className="src" />
          <h3>Hp Elitebook </h3>
          <span className="notch"></span>
          </div>
        </Tilt>
        <Tilt transitionSpeed={1000} scale={1.07} className="video-card">
        <div onClick={()=>navigate('/product-details/iPhone-12-128GB-FU--15394')}> 
         <img src="https://terra01.s3.amazonaws.com/images/2fa3e6d0-0767-44ad-98ac-e2e8ddf255a4.jpeg" alt="" className="src" />
          <h3>Iphone 12  </h3>
          <span className="notch"></span>
          </div>
        </Tilt>
        
      </div>
    </>
  );
};
