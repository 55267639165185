import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
  useCallback,
} from "react";
import { addToCarts, getCarts, removeFromCarts, updateCarts } from "../services/cart-services";
import { getCartService } from "../services/cart-services/getCartService";
import { useAuth } from "./AuthProvider.js";
import { getWishlistService } from "../services/wishlist-services/getWishlistService";
import { addToWishlistService } from "../services/wishlist-services/addToWishlistService";
import { removeFromWishlistService } from "../services/wishlist-services/removeFromWishlist";
import { removeFromCartService } from "../services/cart-services/removeFromCartService";
import { getAddressListService } from "../services/address-services/getAddressListService";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { changeQuantityCartService } from "../services/cart-services/changeQuantityCartService";
import { userDataReducer, initialUserData } from "../reducer/userDataReducer";
import { clearCartService } from "../services/cart-services/clearCartService";
import { addToWishlists, getWishlists, removeFromWishlists } from "../services/wishlist-services/index.js";
import { getAddress } from "../services/address-services/index.js";
import { getOrders } from "../services/order-services/index.js";
import { gaTrackEvent } from "../utils/analytics.js";
import { getPromo, swapProductRequest } from "../services/products-services/index.js";

const UserDataContext = createContext();

export function UserProvider({ children }) {
  const [cartLoading, setCartLoading] = useState(false);
  const [swapLoading, setSwapLoading] = useState(false);
  const [activeSize, setActiveSize] = useState('');
  const [listDisplayType, setListDisplayType] = useState('');
  const [, setError] = useState("");
  const [userDataState, dispatch] = useReducer(
    userDataReducer,
    initialUserData
  );

  const { auth, setIsLoginModalOpen } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    setListDisplayType(localStorage.getItem('listDisplayType') || "product-column")
    if(window.localStorage.getItem('token')){
      getCartProducts()
      getWishlistProducts()
      getOrderItemsHandler()
    }
  }, [])

  const setDisplayType = (listtype) => {
    setListDisplayType(listtype)
    localStorage.setItem('listDisplayType', listtype)
  }

  const addToCartHandler = async (product, buynow = null) => {
    if (auth.isAuth) {
      if (product.is_stock < 1) {
        toast.error(`Sorry, ${product.title} is not in stock.`);
      } else if (product.size.length && !activeSize) {
        toast.error(`Sorry, Please select a size`);
      } else {
        if (!isProductInCart(product)) {
          try {
            setCartLoading(true);
            setError("");
            const response = await addToCarts({ productId: product._id, size: activeSize });
            setCartLoading(false);
            toast.success(`${product.title} added to cart successfully!`);
            window.fbq('track', 'AddToCart');
            gaTrackEvent({ type: 'add_to_cart', category: "Ecommerce", data: { size: activeSize, ...product, } })
            window.fbq('track', 'AddToCart', {
              content_ids: [product.slug], // 'REQUIRED': array of product IDs
              content_type: 'product', // RECOMMENDED: Either product or product_group based on the content_ids or contents being passed.
            });
            dispatch({ type: "ADD_TO_CART", payload: { ...response.data, size: activeSize } });
            if(buynow){
              navigate("/cart")
            }
            // setActiveSize("")
          } catch (error) {
            setCartLoading(false);
            // setActiveSize("")
            console.log(error);
          } finally {
            setCartLoading(false);
          }
        } else {
          navigate("/cart");
        }
      }
    } else {
      // toast("Please login first!");
      setIsLoginModalOpen(true)
      // navigate("/login", { state: { from: location } });
    }
  };

  const addToWishlistHandler = async (product) => {
    const response = await addToWishlistService(product);
    dispatch({ type: "ADD_TO_WISHLISTS", payload: response.data.wishlist });
  };

  const getCartProducts = async () => {
    try {
        setCartLoading(true);
        setError("");
        const response = await getCarts();
        dispatch({ type: "LOAD_CART", payload: response.data.docs });
        setCartLoading(false);
    } catch (error) {
      setCartLoading(false);
      console.log(error);
    } finally {
      setCartLoading(false);
    }
  }

  const removeFromCartHandler = async ({ _id, title }) => {
    try {
      setCartLoading(true);
      setError("");
      const response = await removeFromCarts(_id);
      if (response.status === 200) {
        setCartLoading(false);
        toast.success(`${title} successfully removed from the cart `);
        dispatch({ type: "LOAD_CART", payload: userDataState.cartProducts?.filter((item) => item._id != _id) });
      }
    } catch (error) {
      setCartLoading(false);
      console.log(error);
    } finally {
      setCartLoading(false);
    }
  };

  const cartCountHandler = async ({ title, _id, qty, type, productId, size }) => {
    try {
      setCartLoading(true);
      setError("");
      if (type === "decrement" && qty === 1) {
        const response = await removeFromCarts(_id);
        setCartLoading(false);
        toast.success(`${title} successfully removed from the cart`);
        dispatch({ type: "LOAD_CART", payload: userDataState.cartProducts?.filter((item) => item._id != _id) });
      } else {
        var byVal = type == 'decrement' ? -1 : 1
        const response = await updateCarts({
          cartId: _id,
          qty: byVal,
          productId, size
        });

        setCartLoading(false);
        if (type === "decrement") {
          toast.success(`Removed one ${title} from the cart!`);
        } else {
          toast.success(`Added another ${title} to the cart!`);
        }
        dispatch({
          type: "LOAD_CART", payload: userDataState.cartProducts?.map((item) => {
            if (item._id == _id) {
              item.qty += byVal
            }
            return item
          })
        });
      }
    } catch (error) {
      setCartLoading(false);
      toast.error(error?.response?.data?.errors[0]);
    }
  };

  const wishlistHandler = async ({ title, productId }) => {
    if (auth.isAuth) {
      if (!isProductInWishlist(productId)) {
        try {
          setCartLoading(true);
          setError("");
          window.fbq('track', 'AddToWishlist');
          gaTrackEvent({ type: 'add_to_wishlist', category: "Ecommerce", data: { title } })
          const response = await addToWishlists({ productId });

          setCartLoading(false);
          toast.success(
            `${title} added to the wishlist successfully!`
          );
          dispatch({ type: "ADD_TO_WISHLISTS", payload: response.data });

        } catch (error) {
          setCartLoading(false);
          console.log(error);
        } finally {
          setCartLoading(false);
        }
      } else {
        try {
          setCartLoading(true);
          setError("");
          const response = await removeFromWishlists(productId);

          setCartLoading(false);
          toast.success(
            `${title} removed from the wishlist successfully!`
          );
          dispatch({ type: "LOAD_WISHLISTS", payload: userDataState.wishlistProducts?.filter((item) => item.productId?._id != productId) });

        } catch (error) {
          setCartLoading(false);
          console.log(error);
        } finally {
          setCartLoading(false);
        }
      }
    } else {
      // toast("Please login first!");
      setIsLoginModalOpen(true)
      // navigate("/login", { state: { from: location } });
    }
  };
  const getOrderItemsHandler = useCallback(async () => {
    try {
      const response = await getOrders();
      dispatch({ type: "LOAD_ORDERS", payload: response.data.docs });
    } catch (error) {
      console.log(error);
    }
  }, [auth, dispatch]);

  const getWishlistProducts = useCallback(async () => {
    try {
      const response = await getWishlists();
      dispatch({ type: "LOAD_WISHLISTS", payload: response.data.docs });
    } catch (error) {
      console.log(error);
    }
  }, [auth, dispatch]);

  const removeFromWishlistHandler = async ({ productId, title }) => {
    try {
      setCartLoading(true);
      setError("");
      const response = await removeFromWishlists(productId);
      setCartLoading(false);
      toast.success(
        `${title} removed from the wishlist successfully!`
      );
      dispatch({ type: "LOAD_WISHLISTS", payload: userDataState.wishlistProducts?.filter((item) => item.productId?._id != productId) });
    } catch (error) {
      setCartLoading(false);
      console.log(error);
    } finally {
      setCartLoading(false);
    }
  };

  const sendSwapRequest = async (obj) => {
    try {
      setSwapLoading(true);
      setError("");
        const response = await swapProductRequest(obj);
        setSwapLoading(false);
        // toast.success("Swap form submitted succesfully. You will be contacted via email")
        window.location.assign("/request-success")
    } catch (error) {
      setSwapLoading(false);
      toast.error(error?.response?.data?.errors[0]);
    }
  };

  const isProductInCart = (product) => {
    const found = userDataState.cartProducts.find(
      (item) => (item.productId?._id === product._id) && (activeSize == item.size)
    );
    return found ? true : false;
  };

  const isProductInWishlist = (productId) => {
    const found = userDataState.wishlistProducts.find(
      (item) => item.productId?._id === productId
    );
    return found ? true : false;
  };

  const totalDiscountedPrice = userDataState.cartProducts?.reduce(
    (acc, curr) => acc + curr.discounted_price * curr.qty,
    0
  );

  const totalOriginalPrice = userDataState.cartProducts?.reduce(
    (acc, curr) => acc + curr.original_price * curr.qty,
    0
  );

  const discountPercent = () => {
    const totalPrice = userDataState?.cartProducts?.reduce(
      (acc, curr) => ({
        ...acc,
        original: acc.original + curr.original_price,
        discount: acc.discount + curr.discounted_price,
      }),
      { original: 0, discount: 0 }
    );

    const totalDiscount =
      (totalPrice.original - totalPrice.discount) / totalPrice.original;

    return totalDiscount?.toFixed(2) * 100;
  };

  const clearCartHandler = async () => {
    try {
      dispatch({ type: "LOAD_CART", payload: [] });
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   getWishlistProducts();
  //   getCartProducts();
  //   getAddressList();
  // }, [auth, getWishlistProducts, getCartProducts, getAddressList]);

  return (
    <UserDataContext.Provider
      value={{
        userDataState,
        dispatch,
        addToCartHandler,
        addToWishlistHandler,
        removeFromWishlistHandler,
        isProductInCart,
        removeFromCartHandler,
        isProductInWishlist,
        totalDiscountedPrice,
        totalOriginalPrice,
        discountPercent,
        initialUserData,
        wishlistHandler,
        cartCountHandler,
        sendSwapRequest,
        swapLoading,
        cartLoading,
        clearCartHandler,
        setDisplayType,
        listDisplayType,
        activeSize,
        setActiveSize
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
}

export const useUserData = () => useContext(UserDataContext);
