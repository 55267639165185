import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";

// import { getCategori } from "../services/services";
// import { getAllProducts } from "../services/services";
import { dataReducer, initialState } from "../reducer/dataReducer";
import { getCategories, getProducts, getPromo } from "../services/products-services";
import { gaTrackEvent } from "../utils/analytics";
import { useSearchParams } from 'react-router-dom';

const DataContext = createContext();

export function DataProvider({ children }) {
  const [state, dispatch] = useReducer(dataReducer, initialState);
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [, setError] = useState(false);
 

  const getAllProducts = async (filter = {limit: 12, page: 1}) => {
    try {
      // const obj = checkForFilter()
      gaTrackEvent({ type: 'filter', label: 'Filter', category: 'Filter', data: searchParams.toString() })

      setError(false);
      setLoading(true);
      var params = searchParams.toString() ? searchParams.toString() + "&limit="+filter.limit + '&page=' +filter.page : `limit=${filter.limit}&page=${filter.page}`

      const response = await getProducts(params);
      const {nextPage, prevPage, page, docs} = response.data
      setLoading(false);
      dispatch({
        type: "GET_ALL_PRODUCTS_FROM_API",
        payload: {data: searchParams.get("title") ? [...docs] : [
          ...docs
            .map((value) => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value),
        ], nextPage, prevPage, page},
      });
    } catch (error) {
      setError(true);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getProduct = async (filter = {}) => {
    try {
      setError(false);
      setLoading(true);
      const response = await getProducts(`slug=${encodeURIComponent(filter.slug)}`);
      setLoading(false);
      dispatch({
        type: "GET_PRODUCT",
        payload: response.data.docs.length ? response.data.docs[0] : {} ,
      });
    } catch (error) {
      setError(true);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const getAllCategories = async () => {
    try {
      const response = await getCategories();
      dispatch({
        type: "GET_ALL_CATEGORIES",
        payload: response.data.docs,
      });
    } catch (error) {
      console.log(error);
    }
  };


  const getFlashSale = async () => {
    try {
      const response = await getPromo();
      dispatch({ type: "SET_PROMO", payload: response.data });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    // getAllProducts();
    getFlashSale()
    getAllCategories();
  }, []);

  return (
    <DataContext.Provider value={{ state, dispatch, loading, getAllProducts, getProduct }}>
      {children}
    </DataContext.Provider>
  );
}

export const useData = () => useContext(DataContext);
