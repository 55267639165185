import { BsFillStarFill } from "react-icons/bs";
import "./MoreInfo.css";
import { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FAQAccordion from "./Faq";

export const MoreInfo = () => {
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 2,
        autoplay: false,
        autoplaySpeed: 2000,
        nextArrow: null,
        prevArrow: null,
    };
    return (
        <div className="x-more-info">
            <div class="promo-banner">
                <h2 class="promo-title">Place your order in seconds</h2>
                <p class="promo-text">Tech shopping just got easier! Download the 360GadgetsAfrica app now and explore the latest gadgets at your fingertips! 📲✨</p>
                <a class="download-button"  
                 onClick={()=>window.fbq('track', 'Lead')}
                 target="__blank" href="https://play.google.com/store/apps/details?id=com.gadgetsafrica.gadgetsafrica">
                    <img src="data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path d='M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z' fill='%235f6368'/></svg>" alt="Google Play icon" />
                    {/* Download on Google Play */}
                    Download on Google Play
                </a>
                <a class="download-button" 
                 onClick={()=>window.fbq('track', 'Lead')}
                 target="__blank" href="https://apps.apple.com/us/app/360gadgetsafrica/id6736353137">
                    <img src="data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'><path d='M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z' fill='%23000000'/></svg>" alt="App Store icon" />
                    {/* Download on App Store */}
                    Coming soon on Apple Store
                </a>
                {/* <div class="promo-code">CDNWEB</div> */}
                <h3 class="review-title">Customer Reviews</h3>
                <div style={{position: 'relative'}}>
                <Slider {...settings} >
               <div>
               <div className="customer-review">
                        <div className="x-review-card">
                            <img className="x-profilepic" src="https://d4ian0pscdjji.cloudfront.net/profilepic.jpg" />
                            <div className="x-desc">
                                <small>Motunrayo Otaru</small>
                                <p className="x-ratings">
                                    <BsFillStarFill color={"orange"} />
                                    <BsFillStarFill color={"orange"} />
                                    <BsFillStarFill color={"orange"} />
                                    <BsFillStarFill color={"orange"} />
                                    <BsFillStarFill color={"orange"} />
                                    <BsFillStarFill color={"orange"} />
                                </p>
                                <p>So convenient! I found the latest gadgets with just a few taps. Highly recommend!</p>
                                <video  controls src="https://360gadgetsafrica.s3.eu-north-1.amazonaws.com/IMG_2468.MOV" />
                            </div>
                        </div>
                    </div>
               </div>
               <div>
                    <div className="customer-review">
                        <div className="x-review-card">
                            <img className="x-profilepic" src="https://360gadgetsafrica.s3.eu-north-1.amazonaws.com/2caf6289-4fa3-434b-8520-40ef23bef73a.JPG" />
                            <div className="x-desc">
                                <small>Olamide Aremu</small>
                                <p className="x-ratings">
                                    <BsFillStarFill color={"orange"} />
                                    <BsFillStarFill color={"orange"} />
                                    <BsFillStarFill color={"orange"} />
                                    <BsFillStarFill color={"orange"} />
                                    <BsFillStarFill color={"orange"} />
                                    <BsFillStarFill color={"orange"} />
                                </p>
                                <p>I tried 360GadgetsAfrica, their case is of high quality and their delivery is swift. Awesome stuff </p>
                                <video  controls src="https://d4ian0pscdjji.cloudfront.net/IMG_2395+3.mov" />
                            </div>
                        </div>
                    </div>
                    </div>
                </Slider>
                </div>
                <h3 class="review-title">F.A.Q</h3>
                <FAQAccordion />
            </div>
        </div>
    )
}
